<template>
    <b-modal id="profile-selection" 
             centered 
             :size="fields.length > 0 ? 'xl' : method ? 'lg' : 'md'" 
             @hide="reset">


        <template #modal-title>
            <i v-if="method"
               :class="`fas ${method.icon}`"
               :style="{ color: method.color, 'margin-right': '12px' }">
            </i>

            <span v-if="customExcelSelectionTitle">
                {{customExcelSelectionTitle}}
            </span>
            <span v-else>
                Definir lista de transmissão{{method && method.label ? ` ${method.label}` : '...'}}
            </span>
        </template>

        <b-container v-if="!method " 
                     class="profile-selection-picker">


            <b-row v-for="method in methods"
                   @click="setMethod(method)"
                   :class="`profile-selection-method py-2${method.isLoading ? ' loading' : ''}`">


                <b-col  cols="2" 
                       md="auto"> 

                    <i :class="`fas ${method.icon}`" 
                       :style="{ color: method.isLoading ? 'var(--cinza-5)' : method.color }">
                    </i>
                </b-col>


                <b-col>{{method.label[0].toUpperCase() + method.label.slice(1)}}</b-col>

                <b-col v-if="method.isLoading" 
                       cols="1" 
                       md="auto">
                    <b-spinner small variant="secondary"></b-spinner>
                </b-col>
            </b-row>
        </b-container>


        <b-container v-if="method" 
                     class="profile-selection-method-container">

            <div v-if="method?.name == 'perfil-de-campanha'">
                <div v-if="perfis">
                    <h6>Perfils de campanha disponíveis</h6>
                    <select class="w-100" 
                            v-model="perfil" 
                            @change="rows = null">

                        <option v-for="perfil in perfis" 
                                :value="perfil">{{perfil.titulo}}</option>
                    </select>
                </div>
                <div v-else 
                     class="text-danger">
                    Não encontramos perfis cadastrados na base.
                </div>
            </div>


            <div v-if="method?.name == 'arquivo-excel'" 
                 class="profile-selection-excel-file">

                <b-row>
                    <b-col cols="4">
                        <h6 class="text-truncate">
                            Pasta de trabalho
                        </h6>

                        <label for="excel-file-picker" 
                               class="text-truncate" 
                               :title="file?.fileName">

                            <i class="fas fa-file-excel"></i>
                            {{file?.fileName ?? "Nenhum arquivo selecionado..."}}
                        </label>

                        <input type="file" 
                               ref="excel-file" 
                               id="excel-file-picker" 
                               accept=".xls, .xlsx" 
                               @change="pickExcel" />
                    </b-col>


                    <b-col cols="4">
                        <h6 class="text-truncate">Planilha</h6>
                        <select class="w-100" 
                                v-model="sheet" 
                                :disabled="!file">

                            <option v-for="sheet in (file?.sheets ?? [])" 
                                    :value="sheet">
                                {{sheet?.name}}
                            </option>
                        </select>
                    </b-col>


                    <b-col cols="4">
                        <h6 class="text-truncate">
                            Cabeçalho
                        </h6>

                        <select class="w-100" 
                                v-model="(sheet ?? {}).hasHeader" 
                                :disabled="!file">
                            <option :value="true">Presente</option>
                            <option :value="false">Ausente</option>
                        </select>
                    </b-col>
                </b-row>


                <b-row v-if="isFileLoading">
                    <b-col class="mt-3 text-center text-secondary">
                        <b-spinner variant="secondary" 
                                   small class="mr-1">
                        </b-spinner>
                        Carregando...
                    </b-col>
                </b-row>


                <b-row v-if="!isFileLoading && file && fields.length == 0">
                    <b-col class="mt-3">
                        <h6 class="text-center">Não há dados disponíveis na planilha selecionada.</h6>
                    </b-col>
                </b-row>
            </div>

            <div v-if="method?.name == 'selecao-manual'">
                <b-row class="d-flex align-items-center">
                    <b-col cols="6">
                        <h6>Escolha os clientes que farão parte da lista de transmissão:</h6>
                    </b-col>
                    <b-col align="end">
                        <button class="rounded-0 button primary-button" @click="toggleFilter(retornoFiltros)"><i class="fas fa-filter fa-sm fa-fw mr-3 cursor-pointer"
                        ></i>Adicionar filtros</button>
                    </b-col>
                </b-row>
            </div>


            <div v-if="fields.length > 0">
                <br />
                <h6 v-if="file && mandatoryFields.length > 0">
                    Relacione
                    <span v-for="campo in mandatoryFields.map(campo => colunasCliente.filter(coluna => coluna.value == campo)[0].label)"
                          class="text-danger campo-obrigatorio">{{campo}}</span>
                    com {{mandatoryFields.length == 1 ? "sua respectiva coluna" : "suas respectivas colunas"}} da planilha
                </h6>
                <b-table ref="clientes-selector"
                         :fields="fields"
                         :items="items"
                         :selectable="fields[0].key == 'select'"
                         select-mode="multi"
                         :selected = "rowsSelected"
                         @row-selected="toggleRowSelection"
                         selected-variant="primary"
                         responsive
                         sticky-header
                         no-border-collapse
                         striped
                         :class="carregou ? 'tabelaOpacidade data-viewer app-scroll-custom' : 'data-viewer app-scroll-custom'">

                    <template #head(select)="scope">
                        <input type="checkbox" 
                               :checked="contagemAll == contagem"
                               @change="selectAllRows($event.target.checked)" />  
                    </template>


                    <template #head(index)="scope">   
                        #
                    </template>


                    <template #head()="scope">
                        <select v-if="file && method?.name == 'arquivo-excel'"
                                v-model="headerMap[scope.field.key]"
                                :class="`header-selector w-100 p-0 border-0 font-weight-bold${headerMap[scope.field.key] ? '' : ' undefined-column'}`">

                            <option :value="null">{{scope.field.label}}</option>

                            <optgroup label="Dado do Cliente">

                                <option v-for="coluna in colunasCliente"
                                        v-if="!Object.keys(headerMap).filter(item => item != scope.field.key).map(item => headerMap[item]).includes(coluna.value)"
                                        :value="coluna.value"
                                        :class="coluna.mandatory ? 'text-danger font-weight-bold' : ''">
                                    {{coluna.label}} 
                                </option>
                            </optgroup>
                        </select>


                        <div v-else class="text-nowrap">{{scope.label}}</div>
                    </template>


                    <template #cell(select)="{index, rowSelected }">
                        <input type="checkbox" 
                               :checked="rowSelected" 
                               @change="changeRowSelection(index, $event.target.checked)" />
                    </template>


                    <template #cell(index)="data">
                        {{data.index + pageLimit * (selectedPage - 1) + 1}}
                    </template>


                    <template #cell()="data">
                        {{data.value}}
                    </template>


                </b-table>
                <div v-if="invalidRows.length > 0 && (mandatory || !isIportationClient)">
                    <i class="fas fa-exclamation-circle text-danger mr-1"></i>
                    <span v-if="invalidRows.length > 1">
                        As seguintes linhas são inválidas:
                        <span v-for="row in invalidRows" class="text-danger campo-obrigatorio">
                            <b>{{row + 1}}</b>
                        </span>.
                    </span>


                    <span v-else>
                        A linha
                        <span class='text-danger campo-obrigatorio'>
                            <b>{{invalidRows[0] + 1}}</b>
                        </span>
                        é inválida.
                    </span>
                </div>

                <span v-if="contagem > 0 && !carregou">
                    <b>Total:</b>
                    {{contagem }} selecionado{{contagem > 1 ? "s" : ""}}
                </span>
                <span v-else-if="contagem > 0 && carregou">
                    <b-spinner variant="secondary" small class="mr-1"></b-spinner>
                    <b>Carregando</b>
                </span>


                <div v-if="noContent" class="text-center text-secondary">
                    Não encontramos nenhum cliente na base de dados para listagem.
                </div>


                <div v-else-if="rows && rows.length == 0" class="text-center text-secondary">
                    <b-spinner variant="secondary" small class="mr-1"></b-spinner>
                    Carregando...
                </div>
            </div>

            <div v-if="pagesCountManual > 1" 
                     class="data-viewer-pagination text-center mt-3">
                    <b-button variant="light"
                              class="rounded-0"
                              :disabled="!selectedPage || selectedPage < 2"
                              @click="selectedPage = 1">
                        <i class="fas fa-angle-double-left"></i>
                    </b-button>


                    <b-button variant="light"
                              class="rounded-0"
                              :disabled="!selectedPage || selectedPage < 2"
                              @click="selectedPage -= 1">
                        <i class="fas fa-angle-left"></i>
                    </b-button>


                    <b-button v-for="page in pagesManual"
                              :key="`page-${page}`"
                              :variant="page == selectedPage ? 'dark' : 'light'"
                              :disabled="page == selectedPage"
                              class="rounded-0"
                              @click="selectedPage = page">
                        {{page}}
                    </b-button>


                    <b-button variant="light"
                              class="rounded-0"
                              :disabled="!selectedPage || selectedPage > pagesCountManual - 1"
                              @click="selectedPage += 1">
                        <i class="fas fa-angle-right"></i>
                    </b-button>


                    <b-button variant="light"
                              class="rounded-0"
                              :disabled="!selectedPage || selectedPage > pagesCountManual - 1"
                              @click="selectedPage = pagesCountManual">
                        <i class="fas fa-angle-double-right"></i>
                    </b-button>
                </div>
        </b-container>


        <template #modal-footer="{ cancel }">
            <b-button v-if="method && !isIportationClient && !disableMethodSelection" @click="setMethod(null)" variant="light" class="rounded-0">
                <i class="fas fa-angle-left mr-1"></i>
                Voltar
            </b-button>


            <b-button variant="light" 
                      @click="cancel()" 
                      class="rounded-0">
                Cancelar
            </b-button>


            <b-button v-if="method" 
                      variant="success" 
                      class="rounded-0" 
                      @click="confirm" 
                      :disabled="!isReady">
                Confirmar
            </b-button>
        </template>
        <ModalFiltrosClientes ref="Campanha-Filtros" />
    </b-modal>
</template>



<script>
    import ExcelFile from "@/assets/js/readers/excel.js";
    import ModalFiltrosClientes from "@/components/filtros-cliente/ModalFiltroClientes.vue";
    import axios from "axios";

    export default {
        name: "ModalSelecaoDestinatarios",
        props: {
            mandatoryFields: {
                type: Array,
                default() {
                    return [];
                }
            },
            isIportationClient: Boolean,
        },
        components: {
            ModalFiltrosClientes
        },
        data() {
            return {
                perfis: null,
                perfil: null,
                colunasCliente: [
                    {
                        value: "CnpjCpf",
                        label: "CNPJ/CPF",
                        alias: "cnpjcpf,cpf,cnpj,cpfcnpj"
                    },
                    {
                        value: "RazaoSocial",
                        label: "Nome/Razão Social",
                        alias: "nomerazaosocial,razaosocial,nome,nomecompleto,pseudonimo,empresa,nomeempresa,nomedaempresa,nomecontato,contato,nomedecontato"
                    },
                    {
                        value: "Email",
                        label: "Email",
                        alias: "email,enderecodeemail"
                    },
                    {
                        value: "Telefone",
                        label: "Telefone",
                        alias: "telefone,fone,celular,whatsapp,numerotelefone,numerodetelefone"
                    },
                    {
                        value: "Municipio",
                        label: "Cidade",
                        alias: "municipio,cidade"
                    },
                    {
                        value: "UF",
                        label: "Estado",
                        alias: "uf,estado"
                    }
                ].map(item => ({ ...item, mandatory: this.mandatoryFields.includes(item.value) })),
                methods: [
                    
                    {
                        name: "arquivo-excel",
                        label: "de uma pasta de trabalho do Excel (.xls, .xlsx)",
                        icon: "fa-file-excel",
                        color: "#1f7349"
                    },
                    {
                        name: "selecao-manual",
                        label: "de uma seleção manual da base de clientes",
                        icon: "fa-user-friends",
                        color: "#333",
                        isLoading: false
                    }
                ],
                method: null,
                file: null,
                sheet: null,
                headerMap: null,
                listaIds: [],
                resolve: null,
                rows: null,
                carregou: null,
                contagem: 0,
                contagemAll: -1,
                rowsSelected: [],
                noContent: false,
                camposPadrao:
                    [
                        { key: "select", stickyColumn: true, variant: "primary" },
                        { key: "Nome", label: "Nome" },
                        { key: "Telefone", label: "Telefone" },
                        { key: "Email", label: "Email" },
                        { key: "CnpjCpf", label: "CNPJ/CPF" },
                        { key: "CidadeUf", label: "Cidade/UF" },
                        { key: "DataCadastro", label: "Data de Cadastro" }
                    ],
                dadosComplementos:[],
                retornoFiltros: [],
                camposPersonalizados: [],
                selecaoTeste: {},
                pageSelections: [],
                listaFiltros : [],
                isFileLoading: false,
                totalItems: 0,
                pageLimit: 100,
                selectedPage: 1,
                autoSelectItemsTimeout: null,
                autoSelectLocked: false,
                customExcelSelectionTitle: null,
				disableMethodSelection: true
            }
        },
        created(){
            axios.get("api/v2/clientes/BuscarConfiguracoes").then(res => {
                this.colunasCliente = [
                    ...this.colunasCliente,
                    ...res.data.map(comp => ({
                        key: comp.Id,
                        label: comp.NomeCampo,
                        value: comp.Id,
                        mandatory: false
                    }))
                ];
            });
        },
        computed: {
            fields() {
                switch (this.method?.name) {
                    default:
                        return [];
                    case "perfil-de-campanha": case "selecao-manual":
                        {
                            if (this.method?.name == "perfil-de-campanha" && !this.perfil) return [];
                            let fields = [...this.camposPadrao.map(campo => ({
                                label: campo.label,
                                key: campo.key
                            })), ...(this.camposPersonalizados.filter(campo => campo.Id || campo.key).map(campo => ({
                                label: campo.NomeCampo ?? campo.label,
                                key: campo.Id ?? campo.NomeCampo ?? campo.key
                            })) ?? [])];
                            return fields;
                        }
                    case "arquivo-excel":
                        {
                            if (!this.sheet) return [];
                            return this.sheet.header.length > 0 ? [{ key: "index", stickyColumn: true, variant: "success" }, ...this.sheet.header.map((field, index) => ({ key: String.fromCharCode(index + 65), label: field }))] : [];
                        }
                }
            },
            items() {
                switch (this.method?.name) {
                    default:
                        return [];
                    case "perfil-de-campanha":
                        {
                            if (this.perfil && !this.rows) {
                                this.rows = [];
                                this.noContent = false;
                                let campanhaId = this.perfil.Id;

                                axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
			                    params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}
                                } : {params: {pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}})
                                    .then(response => {
                                        if (response.ClientesManualDTO.length == 0) this.noContent = true;      
                                        this.rows = response.ClientesManualDTO;
                                        this.totalItems = response.TotalItems
                                        if (this.rows) setTimeout(() => { this.selectAllRows(); }, 100);
                                    }).catch(erro => {
                                        this.rows = [];
                                        console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                                    });
                            }
                            return this.rows ?? [];
                        }
                    case "arquivo-excel":
                        {
                            this.noContent = false;
                            if (!this.sheet) return [];
                            let rangeOut = this.selectedPage * this.pageLimit,
                                rangeIn = rangeOut - this.pageLimit;
                            return this.sheet.rows.slice(rangeIn, rangeOut).map((item, row) => {
                                return Object.assign({}, ...this.sheet.header.map((value, index) => ({ [String.fromCharCode(index + 65)]: item[index] })), this.invalidRows.includes(row + 100 * (this.selectedPage - 1)) ? { _rowVariant: "danger" } : null);
                            });
                        }
                    case "selecao-manual":
                        {                     
                            if (!this.rows) {
                                this.rows = [];
                                this.noContent = false;
                                let campanhaId = null;

                                this.carregou = this.autoSelectItemsTimeout;

                                axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
			                    params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}
                                } : {params: {pageCount: this.selectedPage-1,pageLimit: this.pageLimit}}).then(response => {
                                        if (response.data.ClientesManualDTO.length == 0) this.noContent = true;
                                        this.rows = response.data.ClientesManualDTO.length == 0 ? [] : response.data.ClientesManualDTO;  
                                        this.rows = this.rows.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))
                                        this.totalItems = response.data.TotalItems;
                                        this.carregaComplementos();
                                        this.autoSelectItemsTimeout = setTimeout(() => {
                                                this.autoSelectLocked = false;
                                                this.carregou = null;
                                            }, 300)
                                    }).catch(erro => {
                                        this.rows = [];
                                        console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                                    });                 
                            }

                            return this.rows ?? [];
                        }
                }
            },
            invalidRows() {
                if (!this.sheet) return [];
                let mandatoryFieldsMap = this.mandatoryFields.map(field => Object.values(this.headerMap).indexOf(field)).filter(item => item >= 0);
                return this.sheet.rows.map((row, index) => ([index, row])).filter(item => mandatoryFieldsMap.length > 0 && mandatoryFieldsMap.filter(field => item[1][field]?.toString().trim().length > 0).length == 0).map(item => item[0]);
            },
            isReady() {
                if (this.rows && this.contagem == 0) return false;
                if (this.sheet && this.mandatoryFields.filter(item => {
                    return Object.values(this.headerMap).filter(column => column == item).length == 0
                }).length > 0) return false;
                if (this.sheet && this.invalidRows.length == this.sheet.rows.length) return false;
                if (this.items.length > 0) return true;
                return false;
            },
            pagesCountManual() {
                if(this.method?.name == 'selecao-manual'){
                    if (!this.rows) return [];
                    return Math.ceil((this.totalItems/ this.pageLimit));
                }
                if(this.method?.name == 'arquivo-excel'){
                    if (!this.sheet) return [];
                    return Math.ceil(this.sheet.rows.length / this.pageLimit);
                }
            },
            pagesManual() {
                if(this.method?.name == 'selecao-manual'){
                    if (!this.rows) return [];
                    let firstItem = this.pagesCountManual - this.selectedPage >= 4 ? this.selectedPage - 4 : this.pagesCountManual - 8;
                    if (firstItem < 1) firstItem = 1;
                    return new Array(9).fill().map((page, index) => {
                        page = firstItem + index;
                        return page > this.pagesCountManual ? null : page;
                    }).filter(page => page != null);
                }
                if(this.method?.name == 'arquivo-excel'){
                    if (!this.sheet) return [];
                    let firstItem = this.pagesCountManual - this.selectedPage >= 4 ? this.selectedPage - 4 : this.pagesCountManual - 8;
                    if (firstItem < 1) firstItem = 1;
                    return new Array(9).fill().map((page, index) => {
                        page = firstItem + index;
                        return page > this.pagesCountManual ? null : page;
                    }).filter(page => page != null);
                }
            }
        },
        watch: {
            "sheet.header": {
                handler() {
                    if (!this.sheet) {
                        this.selectedPage = 1;
                        return;
                    }
                    this.selectedPage = 1;
                    let usedValues = [];
                    this.headerMap = Object.assign({}, ...this.sheet.header.map((column, index) => {
                        let value = this.getColunaCliente(column).value ?? null;
                        if (value) {
                            if (usedValues.includes(value)) value = null;
                            else usedValues.push(value);
                        }
                        return { [String.fromCharCode(index + 65)]: value };
                    }));
                }
            },
            selectedPage() {
                if(this.method?.name == 'selecao-manual'){
                    this.carregou = this.autoSelectItemsTimeout;
                    clearTimeout(this.autoSelectItemsTimeout);
                    this.autoSelectLocked = true;
                    if (this.$refs["clientes-selector"]) this.$refs["clientes-selector"].$el.scrollTo(0, 0);
                    this.noContent = false;
                    let campanhaId = null;

                    axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
                            params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}
                            } : {params: {pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}})
                            .then(response => {
                            if (response.data.ClientesManualDTO.length == 0) this.noContent = true;
                            this.rows = [];
                
                            this.carregaComplementos();

                            this.totalItems = response.data.TotalItems;

                            this.rows = response.data.ClientesManualDTO.length == 0 ? null : response.data.ClientesManualDTO;
                            this.rows = this.rows.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))

                            this.rowsSelected = this.rows.filter(item => this.pageSelections.includes(item.Id)).map(item => item.Id);
                            this.autoSelectItemsTimeout = setTimeout(() => {
                                this.autoSelectLocked = false;
                                this.rows.forEach((item, index) => {
                                    if (this.pageSelections.includes(item.Id)) this.$refs["clientes-selector"].selectRow(index);
                                });
                                this.carregou = null;
                            }, 300)

                        }).catch(erro => {
                            this.rows = [];
                            console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                        });
                }
            }
        },
        methods: {
            carregaComplementos() {
                axios.get('api/v2/clientes/BuscarConfiguracoes').then(res => {
                    this.camposPersonalizados = [];
                    res.data.forEach(comp => {
                        this.camposPersonalizados.push(comp);
                    });
                });
            },
            getColunaCliente(value) {
                if (value == null) return {};
                if (typeof value == "string") value = value.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/[^\p{L}\p{N}]+/gu, "").toLowerCase();
                return this.colunasCliente.filter(coluna => coluna.alias?.split(",").includes(value.toString()))[0] ?? {};
            },
            changeRowSelection(index, selected) {
                this.$refs["clientes-selector"][selected ? "selectRow" : "unselectRow"](index);
            },
            toggleRowSelection(items) {
                if (this.autoSelectLocked) return;
                this.pageSelections = [
                    ...this.pageSelections.filter(item => !this.rows.find(row => row.Id == item)),
                    ...items.map(item => item.Id)
                ];
                
                this.contagem = this.pageSelections.length;
            },
            selectAllRows(select = true) {
                if (select) {
                    this.carregou = this.autoSelectItemsTimeout;
                    let campanhaId = null;
                    axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
                        params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: 0,pageLimit: 0,clientesParaBuscar: this.listaFiltros}
                        } : {params: {pageCount: 0,pageLimit: 0,clientesParaBuscar: this.listaFiltros}})
                    .then(response => {
                        this.pageSelections = response.data.ClientesManualDTO.map(item => item.Id);

                        this.rowsSelected = this.rows.map(item => item.Id);
                        this.rows.forEach((row, index) => this.$refs["clientes-selector"].selectRow(index));

                        this.contagemAll = response.data.ClientesManualDTO.length;
                        this.contagem = this.contagemAll;
                        
                        this.autoSelectItemsTimeout = setTimeout(() => {
                            this.autoSelectLocked = false;
                            this.carregou = null;
                        }, 300)

                    }).catch(erro => {
                        console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                    });
                } else {
                    this.$refs["clientes-selector"]["clearSelected"]();
                    this.rowsSelected = [];
                    this.contagemAll = -1;
                    this.contagem = 0;
                    this.pageSelections = [];
                }

            },
            async setMethod(method) {
                if (!method) {
                    this.reset(0);
                    return;
                }
                if (method?.name == "perfil-de-campanha") {
                    method.isLoading = true;
                    await axios.get(`api/campanhas/perfis-filtragem`)
                        .then(response => {
                            method.isLoading = false;
                            if (response.data.length == 0) return;
                            this.perfis = response.data.map(perfil => {
                                let locais = perfil.CodigoExterno ? JSON.parse(perfil.CodigoExterno) : {};
                                return {
                                    id: perfil.Id,
                                    titulo: perfil.Titulo,
                                    cidades: locais?.cidades ?? [],
                                    estados: locais?.estados ?? []
                                };
                            });
                        }).catch(erro => {
                            method.isLoading = false;
                            console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro)
                        });
                }
                this.method = method;
            },
            async pickExcel() {
                this.file = null;
                this.sheet = null;
                let file = this.$refs["excel-file"].files[0];
                if (!file) return;
                if (!file.name.split(".").slice(-1)[0].startsWith("xls")) {
                    this.$bvModal.msgBoxOk(`O arquivo selecionado é inválido. Por favor, selecione um arquivo Excel (.xls ou .xlsx).`, {
                        title: "Seleção inválida...",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Entendi"
                    }).then(() => {
                        this.$refs["excel-file"].value = null;
                    });
                    return;
                }
                this.isFileLoading = true;
                this.file = new ExcelFile(file);
                this.file.read().then(() => {
                    this.sheet = (this.file?.sheets ?? [])[0];
                    this.isFileLoading = false;
                });
                return;
            },
            select() {
                this.$bvModal.show("profile-selection");
                return new Promise(resolve => { this.resolve = resolve; });
            },
            async confirm() {

                if (this.rows && this.contagem > 0) {
                    this.resolve({
                        source: "clientes-selection",
                        clientes: this.pageSelections
                    });
                }
                if (this.file && this.method?.name == "arquivo-excel" && this.invalidRows != this.sheet.rows) {
                    if (this.invalidRows.length > 0 && !(await this.$bvModal.msgBoxConfirm(this.invalidRows.length > 1 ? "As linhas inválidas não serão incluídas na lista de transmissão." : `A linha ${this.invalidRows[0] + 1} não será incluída na lista de transmissão porque é inválida.`, {
                        title: "Atenção",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "danger",
                        cancelVariant: "light",
                        okTitle: "Entendi",
                        cancelTitle: "Cancelar"
                    }))) return;
                    let headers = this.sheet.header.map((key, index) => ({ hasRelationship: this.headerMap[String.fromCharCode(index + 65)] != null, value: this.headerMap[String.fromCharCode(index + 65)] ?? `:${key}` }));
                    this.resolve({
                        source: "excel-file",
                        fields: headers.map(item => ({ field: item.value, label: item.hasRelationship ? this.getColunaCliente(item.value).label : item.value.slice(1) })),
                        rows: this.sheet.rows.filter((row, index) => !this.invalidRows.includes(index)).map(item => Object.assign({}, ...headers.map((field, index) => ({ [field.value]: item[index]?.toString() ?? null }))))
                    });
                    if (this.isIportationClient) {
                        var dataClients = [];
                        this.sheet.rows.map(row => {
                            var clients = {};
                            row.forEach((col, index) => {
                                if (headers[index] && headers[index]?.hasRelationship) {
                                    clients[headers[index]?.value.toString()] = col;
                                }
                            });
                            dataClients.push(clients);
                        });
                        this.$emit(`saveImportationClients`, dataClients);
                    }
                }
                setTimeout(() => {
                    this.$bvModal.hide("profile-selection")
                }, 300);
            },
            async getPopUpImpotationToClient() {
                await this.setMethod(this.methods.find(method => method?.name == "arquivo-excel"));
                this.select();
            },
            toggleFilter(retornoFiltros){
                this.$refs["Campanha-Filtros"].iniciarModal("campanha",retornoFiltros ? retornoFiltros : []).then(resolve => {
                    if(this.pageSelections.length > 0){
                        this.$bvModal.msgBoxConfirm("Você possui colunas selecionadas, deseja continuar?", {
                            title: "Atenção",
                            size: "md",
                            buttonSize: "sm",
                            okVariant: "success",
                            okTitle: "Sim",
                            cancelTitle: "Não",
                            centered: true,
                        }).then(confirm => {
                            if (confirm) {
                                this.$refs["clientes-selector"]["clearSelected"]();
                                this.pageSelections = [];
                                this.rowsSelected = [];
                                this.retornoFiltros = resolve.campos;
                                this.listaFiltros = JSON.stringify(resolve.query);
                                if(this.method?.name == 'selecao-manual'){
                                    this.carregou = this.autoSelectItemsTimeout;
                                    clearTimeout(this.autoSelectItemsTimeout);
                                    this.autoSelectLocked = true;
                                    if (this.$refs["clientes-selector"]) this.$refs["clientes-selector"].$el.scrollTo(0, 0);
                                    this.noContent = false;
                                    let campanhaId = null;

                                    axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
                                            params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}
                                            } : {params: {pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}})
                                            .then(response => {
                                            if (response.data.ClientesManualDTO.length == 0) this.noContent = true;
                                            this.rows = [];
                                            this.carregaComplementos();

                                            this.totalItems = response.data.TotalItems;

                                            this.rows = response.data.ClientesManualDTO.length == 0 ? [] : response.data.ClientesManualDTO;
                                            this.rows = this.rows.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))

                                            this.autoSelectItemsTimeout = setTimeout(() => {
                                                this.autoSelectLocked = false;
                                                this.carregou = null;
                                            }, 300)
                                            this.contagemAll = -1;
                                            this.contagem = 0;
                                        }).catch(erro => {
                                            this.rows = [];
                                            console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                                        });
                                }
                            }
                        }); 
                    }
                    else{
                        this.pageSelections = [];
                        this.rowsSelected = [];
                        this.retornoFiltros = resolve.campos;
                        this.listaFiltros = JSON.stringify(resolve.query);
                        if(this.method?.name == 'selecao-manual'){
                            this.carregou = this.autoSelectItemsTimeout;
                            clearTimeout(this.autoSelectItemsTimeout);
                            this.autoSelectLocked = true;

                            if (this.$refs["clientes-selector"]) this.$refs["clientes-selector"].$el.scrollTo(0, 0);

                            this.noContent = false;
                            let campanhaId = null;

                            axios.get(`api/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, this.mandatoryFields[0].toLowerCase() ? {
                                    params: { formaDeContato: this.mandatoryFields[0].toLowerCase() ,pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}
                                    } : {params: {pageCount: this.selectedPage-1,pageLimit: this.pageLimit,clientesParaBuscar: this.listaFiltros}})
                                .then(response => {
                                    if (response.data.ClientesManualDTO.length == 0) this.noContent = true;
                                    this.rows = [];
                                    this.carregaComplementos();

                                    this.totalItems = response.data.TotalItems;

                                    this.rows = response.data.ClientesManualDTO.length == 0 ? [] : response.data.ClientesManualDTO;
                                    this.rows = this.rows.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))

                                    this.autoSelectItemsTimeout = setTimeout(() => {
                                        this.autoSelectLocked = false;
                                        this.carregou = null;
                                    }, 300)
                                    this.contagemAll = -1;
                                    this.contagem = 0;
                                }).catch(erro => {
                                    this.rows = [];
                                    console.error("Não foi possível carregar a lista de Perfis de Campanha.", erro);
                                });
                        }
                    }
                });
			},
			async selecionarContatosDiscagem() {
                await this.setMethod(this.methods.find(method => method?.name == "arquivo-excel"));
				this.customExcelSelectionTitle = "Definir lista de contatos de uma pasta de trabalho do Excel (.xls, .xlsx)";
                this.disableMethodSelection = true;
				return this.select();
			},
            reset(delay = 300) {
                setTimeout(() => {
                    this.perfis = null;
                    this.perfil = null;
                    this.method = null;
                    this.retornoFiltros = null;
                    this.listaFiltros = null;
                    this.file = null;
                    this.sheet = null;
                    this.headerMap = null;
                    this.rows = null;
                    this.contagem = 0,
                    this.contagemAll = -1,
                    this.pageSelections = [];
                    this.rowsSelected = [];
                    this.noContent = false;
                    this.isFileLoading = false;
                    this.selectedPage = 1;
                }, delay);
            }
        }
    }
</script>


<style scoped>
    button.button {
        font-size: 12px;
        padding: 7px 21px;
    }

        button.button + button.button {
            margin-left: 10px;
        }

        button.button[disabled] {
            opacity: .7;
        }

        button.button:not([disabled]):hover {
            filter: brightness(1.1);
        }

        button.button:not([disabled]):active {
            filter: brightness(.95);
        }

        button.button.primary-button {
            border: 0;
            color: #fff;
            background-color: var(--cor-primaria-cliente);
        }

        button.button.secondary-button {
            border: 0;
            color: var(--cinza-5);
            background-color: #fff;
            border-bottom: 2px var(--cinza-5) solid;
        }
    input, select {
        border: 1px var(--cinza-4) solid;
        background-color: var(--cinza-2);
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        transition: all ease-in-out .3s;
    }

        input:focus, select:focus {
            filter: brightness(1.03);
        }
    .tabelaOpacidade{
        opacity: 0.5;
    }
    .profile-selection-method {
        font-size: 16px;
        cursor: default;
        cursor: pointer;
        transition: all ease-in-out .3s;
    }

        .profile-selection-method:hover {
            background-color: var(--cinza-2);
        }

        .profile-selection-method.loading {
            background-color: var(--cinza-2);
            color: var(--cinza-5);
        }

        .profile-selection-method i.fas {
            width: 24px;
            font-size: 24px;
            text-align: center;
        }

    .profile-selection-method {
        font-size: 16px;
        cursor: default;
        cursor: pointer;
        transition: all ease-in-out .3s;
    }

    .profile-selection-picker {
        animation: select-method ease-in .3s;
    }

    .profile-selection-method-container > div {
        cursor: default;
        animation: select-method ease-in .3s;
    }

    .profile-selection-excel-file label {
        width: 100%;
        background-color: var(--cinza-2);
        border: 1px var(--cinza-4) solid;
        padding-right: 12px;
        cursor: pointer;
    }

        .profile-selection-excel-file label > i {
            padding: 7px 12px;
            text-align: center;
            background-color: #1f7349;
            color: #fff;
            font-size: 18px;
            margin-right: 7px;
        }

    .profile-selection-excel-file input[type=file] {
        display: none;
    }

    .data-viewer {
        animation: select-method ease-in .3s;
    }

    .data-viewer-pagination > button {
        width: 50px;
    }

    .header-selector {
        background-color: transparent;
        color: #000;
        border: 0;
    }

    .undefined-column {
        color: var(--cinza-5);
    }

        .undefined-column > optgroup, .undefined-column > optgroup > option {
            color: #000;
        }

    span.campo-obrigatorio + span.campo-obrigatorio:before {
        content: ", ";
        color: #000;
    }

    span.campo-obrigatorio + span.campo-obrigatorio:last-child:before {
        content: " e ";
    }

    @keyframes select-method {
        from {
            margin-left: 25px;
            opacity: 0;
        }

        to {
            margin-left: 0;
            opacity: 1;
        }
    }
</style>